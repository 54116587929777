import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroWordpress from "./../../components/ServiceSinglePage/Hero/Wordpress/HeroWordpress"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"
const heroTextTitle = "Bespoke, WordPress Development"
const heroTextSmallText =
    "A modern web development architecture based on client-side JavaScript, reusable APIs, and prebuilt Markup. There is pretty much nothing you can’t achieve with this tech stack."
const ctaTitle = ""
const heroData = [
    "Our in-house developers have been using WordPress as part of their toolkit since the early days of the original blogging platform. There isn’t much we can’t do with the open source software.",
    "Our clients and developers love working with WordPress, there’s a reason why it has over 60% market share on the world wide web.",
    "More recently, with the explosion of the Jamstack headless technology, Saigon Digital leverages modern frameworks and development workflows to harness the power of the backend of the Content Editing Experience of WordPress whilst developing with the tools and frameworks we love and enjoy, it’s a win win situation!",
    'Sometimes WordPress gets a bad reputation, this is simply due to market share and the ease of access, low barriers to entry it has - in turn this means lots of people contribute to the open source community, developing plugins or themes"which are bloated, not performant and don’t get updated',
    "If you give the right tools to the right team, anything is possible. Saigon Digital can build pretty much anything with WordPress, be it a custom integration with your favourite third-party service or API, bespoke theme development, etc we can help you achieve that."
]
const iconBlockTitle = "Tailored WordPress Development"
const icons = [
    {
        name: "check",
        title: "Bespoke Development",
        text: "We develop custom bespoke themes from the ground up. Have a design that you need to bring to life? We can translate that into a fully functional WordPress theme for you."
    },
    {
        name: "performance",
        title: "Continuous Improvement",
        text: "Growth driven development with continuous improvement for optimum results, once your website is launched, it’s only the beginning of the journey."
    },
    {
        name: "chart",
        title: "Technical SEO",
        text: "Well organised, structured on page SEO for optimum organic presence to allow your business to be visible to search engines."
    },
    {
        name: "content",
        title: "Flexible Content Editing Experience",
        text: "We craft excellent, tailored content editing experiences so your team has a delightful time adding content to the platform."
    },
    {
        name: "bolt",
        title: "Blazing fast frontend",
        text: "Because we develop using the latest tools and frameworks, you can be sure that our development practices are future proof and performant."
    }
]
const cta1 = {
    title: "Enjoying working with the tools you love",
    description:
        "WordPress as a CMS is flexible, easy to use, significantly cost-effective and can be customised according to your every requirement. We build bespoke and pixel perfect tailored WordPress solutions for our clients, so find out how we can help today."
}
const cta2 = {
    title: "Ready to use WordPress on your next project? Reach out to us today for a chat."
}

const Wordpress = ({ location }) => {
    const context = {
        pageName: "Service | Wordpress Development",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroWordpress
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="wordpress"
                    src="../../components/ServiceSinglePage/images/wordpress-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <ThreeColumnContent localisationData={localisationData.wpdev}/>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.wordpressFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Wordpress
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why choose Saigon Digital for WordPress development services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital is an excellent choice for WordPress development services due to our experienced in-house team, who have been working with WordPress since its inception. We offer flexible, cost-effective, and highly customisable solutions tailored to your needs."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is WordPress development?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "WordPress development involves the creation and customisation of websites using the WordPress platform. This includes WordPress theme development services, plugin development, and the integration of various functionalities tailored to specific business needs. As of 2022, approximately 708 million out of 1.3 billion websites use WordPress, with downloads increasing annually."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How much does custom WordPress development cost?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The cost of custom WordPress development services varies based on project complexity and specific requirements. Factors such as bespoke features, design elements, and the overall scope influence the pricing. For detailed cost information, reach out to Saigon Digital."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What are the top benefits of WordPress development services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Top benefits of WordPress development include:\n- User-Friendliness: WordPress is celebrated for its easy-to-use interface.\n- Stability: Regular updates ensure a stable platform.\n- Flexibility: Highly customisable to meet diverse needs.\n- Attractive Templates: Access to a wide range of appealing designs.\n- Easy Management: Simplified content management.\n- Plugins: Extensive plugins for added functionality.\n- Optimised SEO: Enhanced site ranking capabilities.\n- Mobile Optimisation: Responsive design ensures compatibility across devices."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why should you hire a WordPress development agency?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Hiring a specialised WordPress development agency ensures access to the latest techniques and best practices. Their expertise translates into high-quality, efficient work, delivering a customised website that meets your specific business requirements."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How do I choose a WordPress development company?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "When selecting a WordPress development company, ensure they prioritise testing and quality assurance. A reputable agency will have a rigorous testing process to guarantee that your site is free of bugs and errors."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is the usual timeframe for creating a WordPress website?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The development timeline for a WordPress website depends on the project’s complexity. Simple sites might be completed within a few weeks, while more intricate projects could take several months. A detailed project plan will provide a more accurate timeframe."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "WordPress Development | Bespoke WordPress Development Agency | Saigon Digital"
            }
            description={
                "Saigon Digital can build pretty much anything with WordPress, be it a custom integration with your favourite third-party service or API"
            }
        />
    </>
)
